
import { useForm } from '@/util/useForm';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'jumpType',
  setup() {
    const props = defineProps<{
      value?: any;
    }>();
    const emit = defineEmits<{
      change: () => void;
    }>();

    const form = useForm();


    return {
      form,
    };
  },
});
